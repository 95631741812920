#atuacao-nacional
    padding: 0 7rem
    background-color: $white
    height: 100vh
    display: flex
    flex-flow: row wrap
    align-items: center

    .row
        align-items: center

    h1 
        @include title

    p 
        font-size: 1.5rem

    #mapa-card
        background-color: $white
        border: none
        padding: 1rem


@include media-breakpoint-down(md)

    #atuacao-nacional
        padding: 0 2rem

        h1 
            font-size: $h1-mobile
            margin-bottom: 1rem

        p
            font-size: 1rem
            line-height: 1.5rem