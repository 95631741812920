
.slick-slider
    max-width: -webkit-fill-available

    .slick-slide

    .slick-arrow:before
        color: $main-blue
        font-size: 1.5rem

    .carousel-card
        background-color: $white !important
        height: 12rem
        width: auto
        border: none
        margin: 1rem
        padding: 1rem 0.5rem

        img
            height: 100%
            width: 100%
            object-fit: contain

@include media-breakpoint-down(md)
    .slick-slider
        padding: 0

        .slick-slide
            // margin: 0 0.5rem
            width: auto

        .carousel-card
            height: 6rem
            // width: 6rem