body 
    margin: 0
    background-color: $main-blue
    max-width: 100vw !important
    padding: 0 !important

@import 'landing-page'
@import 'conciliador'
@import 'adquirentes'
@import 'quem-somos'
@import 'servicos'
@import 'atuacao-nacional'
@import 'seja-parceiro'
@import 'formulario'
@import 'contato'