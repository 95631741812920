@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@400;600;700;900&display=swap')

$main-font: 'Titillium Web', sans-serif
$font-size-base: 1rem // target ÷ context = rem

body 
    font-family: $main-font

@mixin title 
    font-weight: 700
    font-size: $h1-web