#landing-page
    height: 100vh
    max-width: 100vw
    padding: $body-padding-web
    margin: 0
    box-shadow: inset 0 0 0 100vw rgba($main-blue, 0.6)
    background-color: $main-blue
    background-size: cover
    display: flex
    align-items: center
    justify-content: flex-end

    #landing-row
        flex-direction: row-reverse
        width: 100vw
        padding: 0 4rem

        .landing-col 
            padding: 0 4rem

            #landing-card
                border-radius: 0 !important 
                background-color: $white
                border: none

                #card-text
                    text-align: right
                    font-weight: 600
                    font-size: 3rem

@include media-breakpoint-down(md)

    #landing-row 
        padding: 0 !important

        .landing-col
            padding: 0 1rem 0 0 !important

            #card-text
                text-align: right
                font-weight: 600
                font-size: 2rem !important