#conciliador
    height: 100vh
    padding: 0 0 0 7rem
    color: $white !important
    display: flex
    flex-flow: row
    align-items: center
    justify-content: flex-end
    box-shadow: inset 0 0 0 100vw rgba($main-blue, 0.6)
    background-color: $main-blue
    background-size: cover

    #conciliador-row
        width: 100vw
        height: 100%
        margin-right: 0

        #conciliador-texto
            height: 100%
            display: flex
            flex-flow: column 
            justify-content: center

            #btn-comecar
                margin-top: 1rem
                width: 50%
                color: $secondary-blue

        #conciliador-tela   
            background-size: cover
            background-repeat: no-repeat 

        h1
            @include title
            display: inline-block
            margin-bottom: 0

        h2
            color: $white !important
            font-size: $h2-web
            margin: 0 0 3rem 0 !important

        ul 
            @include list-layout

            li
                font-size: 1.5rem

        #img-conciliador
            width: 100%
            height: 100%
            object-fit: cover


@include media-breakpoint-down(md)
    #conciliador
        padding: 0 2rem

        #conciliador-row
            padding: 0

            #conciliador-texto
                height: 100%

                #btn-comecar
                    margin-top: 0
                    width: 100%

            #conciliador-tela 
                display: none

            h1 
                font-size: $h1-mobile

            h2 
                font-size: $h2-mobile

            ul li
                font-size: $li-mobile