// CORES BASE DO TEMA
$white: #f2f2f2
$black: #0F122E
$main-blue: #3DA6E8
$secondary-blue: #3378C4
$teal-blue: #56C7BE
$turquoise-1: #4EE5D4
$turquoise-2: #20C4D7

$body-padding-web: 0 2rem
$body-padding-mobile: 0 1rem

$h1-web: 4rem
$h1-mobile: 2.5rem
$h2-web: 2rem
$h2-mobile: 1.5rem

$li-mobile: 1.25rem


@mixin list-layout 
    list-style-type: none
    padding-inline-start: 0

@mixin default-button
    background-color: $secondary-blue
    border-color: $secondary-blue
    // border-radius: 0