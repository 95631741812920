#parceiros
    background-color: $white
    height: 100vh
    padding: 0 7rem
    display: flex
    justify-content: center
    align-items: center

    #parceiro-row
        width: 100%
        align-items: center

    #parceiro-text
        height: 100%

        h1 
            @include title
            margin-top: 0

    ul 
        @include list-layout

        li
            font-size: 1.5rem

@include media-breakpoint-down(md)
    #parceiros
        padding: 0 2rem
        background-image: url('../../static/parceiros-img.jpg')
        background-size: cover
        box-shadow: inset 0 0 0 100vw rgba($main-blue, 0.6)
        // height: 50vh

        #parceiro-img
            display: none

        #parceiro-text
            color: $white

            h1 
                font-size: $h1-mobile

            ul li
                font-size: $li-mobile