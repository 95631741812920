#quem-somos
    padding: 0 7rem
    height: 100vh
    max-width: 100vw
    background-color: $white
    color: $black
    display: flex
    align-items: center

    .row
        align-items: center

        h1 
            @include title
            margin-bottom: 1rem
            margin-top: 0

        p
            font-size: 1.5rem

        #quem-somos-img
            border: none
            height: 100%


@include media-breakpoint-down(md)

    #quem-somos
        padding: 0 2rem

        .row

            h1 
                font-size: $h1-mobile

            p
                font-size: 1rem
                line-height: 1.5rem

            #col-img
                display: none
                border: none